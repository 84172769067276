import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError } from 'lib/Notifications';
import Input from 'lib/Input';

interface EditNameModalContentProps {
	onClose(): void;
}

const EditNameModalContent = ({ onClose }: EditNameModalContentProps) => {
	const [firstName, setFirstName] = useState(userContainer.state.v2.first_name);
	const [lastName, setLastName] = useState(userContainer.state.v2.last_name);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!firstName || !lastName) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			first_name: firstName,
			last_name: lastName,
		});
		await userContainer.set(response.data);
		setIsLoading(false);
		onClose();
	};

	return (
		<>
			<TopBar title="Edit name" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>First name</FieldLabel>
					<Input
						placeholder=""
						value={firstName}
						onChange={value => setFirstName(value as string)}
					/>
					<FieldLabel>Last name</FieldLabel>
					<Input placeholder="" value={lastName} onChange={value => setLastName(value as string)} />
					<ActionBar>
						<Button isLoading={isLoading}>Save</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditNameModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form``;

const ActionBar = styled.div`
	margin-top: 24px;
`;
