import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError } from 'lib/Notifications';
import { DateInput } from 'lib/DateInput';

interface EditBirthdayModalContentProps {
	onClose(): void;
}

const EditBirthdayModalContent = ({ onClose }: EditBirthdayModalContentProps) => {
	const [birthdate, setBirhDate] = useState(userContainer.state.v2.birth_date);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!birthdate) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			birth_date: birthdate,
		});
		await userContainer.set(response.data);
		setIsLoading(false);
		onClose();
	};

	const handleDateSelect = (date?: Date) => {
		if (!date) {
			setBirhDate('');
			return;
		}

		setBirhDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
	};

	return (
		<>
			<TopBar title="Edit birth date" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>Birth date</FieldLabel>
					<DateInput
						value={birthdate}
						placeholder={'Select your birthdate'}
						onChange={handleDateSelect}
						allowMonthYearSelection={true}
					/>
					<ActionBar>
						<Button isLoading={isLoading}>Save</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditBirthdayModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form`
	margin-bottom: 320px;
`;

const ActionBar = styled.div`
	margin-top: 24px;
`;
