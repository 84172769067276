import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError } from 'lib/Notifications';
import Input from 'lib/Input';

interface EditIdentityNumberModalContentProps {
	onClose(): void;
}

const EditIdentityNumberModalContent = ({ onClose }: EditIdentityNumberModalContentProps) => {
	const [idNumber, setIdNumber] = useState(userContainer.state.v2.identification_number);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!idNumber) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			identification_number: idNumber,
		});
		await userContainer.set(response.data);
		setIsLoading(false);
		onClose();
	};

	return (
		<>
			<TopBar title="Edit identification number" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>Identification number</FieldLabel>
					<Input
						placeholder="ID number or Passport number"
						value={idNumber}
						onChange={value => setIdNumber(value as string)}
					/>
					<ActionBar>
						<Button isLoading={isLoading}>Save</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditIdentityNumberModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form``;

const ActionBar = styled.div`
	margin-top: 24px;
`;
