import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError, notifyFailure, notifySuccess } from 'lib/Notifications';
import Input from 'lib/Input';
import MobileNumberInput from 'lib/MobileNumberInput';
import { Subscribe } from 'unstated';
import theme from 'config/theme';

const { colors } = theme;

interface EditCellphoneModalContentProps {
	onClose(): void;
}

const EditCellphoneModalContent = ({ onClose }: EditCellphoneModalContentProps) => {
	const [countryCode, setCountryCode] = useState(userContainer.state.v2.cellphone?.region);
	const [otp, setOtp] = useState('');
	const [mobileNumber, setMobileNumber] = useState(
		userContainer.state.v2.cellphone?.number_readable,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [numberSubmitted, setNumberSubmitted] = useState(false);

	const handleSubmit = async () => {
		if (!countryCode || !mobileNumber) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			cellphone: {
				number: mobileNumber,
				region: countryCode,
			},
		});
		if (response.status === 400) {
			const errorMessage = 'Failed to send OTP, please try again later.';
			notifyError(
				response.data.error.message || (response.data.length && response.data[0]) || errorMessage,
			);
			console.log(response.data);
			setIsLoading(false);
			onClose();
			return;
		}
		await userContainer.set(response.data);
		setIsLoading(false);
		setNumberSubmitted(true);
	};

	const handleSubmitOtp = async () => {
		if (!otp) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.user.verifyNumber(`${otp}`);
		if (response.status === 400) {
			notifyFailure('Invalid OTP. Please try again later');
			setIsLoading(false);
			return;
		}
		const userProfileV2Response = await api.userV2.getProfile();
		await userContainer.set(userProfileV2Response.data);
		setIsLoading(false);
		notifySuccess('Cellphone number verified');
		onClose();
	};

	if (numberSubmitted) {
		return (
			<>
				<TopBar title="Enter OTP" onClose={onClose} />
				<Content>
					<Subscribe to={[userContainer]}>
						{user => <NumberDisplay>{user.state.v2.cellphone?.number}</NumberDisplay>}
					</Subscribe>
					<Form
						onSubmit={e => {
							e.preventDefault();
							handleSubmitOtp();
						}}
					>
						<FieldLabel>OTP</FieldLabel>
						<Input
							value={otp}
							onChange={value => setOtp(value as string)}
							placeholder="Enter OTP"
						/>
						<ActionBar>
							<Button isLoading={isLoading}>Submit</Button>
						</ActionBar>
					</Form>
				</Content>
			</>
		);
	}

	return (
		<>
			<TopBar title="Edit cellphone number" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>Cellphone number (WhatsApp preferred)</FieldLabel>
					<MobileNumberInput
						country={countryCode}
						mobileNumber={mobileNumber}
						onCountrySelect={setCountryCode}
						onMobileNumberChange={setMobileNumber}
					/>
					<ActionBar>
						<Button isLoading={isLoading}>Save</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditCellphoneModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form`
	margin-bottom: 240px;
`;

const NumberDisplay = styled.div`
	color: ${colors.grey60};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 24px;
`;

const ActionBar = styled.div`
	margin-top: 24px;
`;
