import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError, notifySuccess } from 'lib/Notifications';
import Input from 'lib/Input';

interface EditEmailModalContentProps {
	onClose(): void;
}

const EditEmailModalContent = ({ onClose }: EditEmailModalContentProps) => {
	const [email, setEmail] = useState(userContainer.state.v2.email?.email);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!email) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			email: { email },
		});
		await userContainer.set(response.data);
		await api.user.sendEmailConfirmation();
		notifySuccess('Verification email sent! Check your mailbox');
		setIsLoading(false);
		onClose();
	};

	return (
		<>
			<TopBar title="Edit email" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>Email</FieldLabel>
					<Input placeholder="" value={email} onChange={value => setEmail(value as string)} />
					<ActionBar>
						<Button isLoading={isLoading}>Send verification</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditEmailModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form``;

const ActionBar = styled.div`
	margin-top: 24px;
`;
