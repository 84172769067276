import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError } from 'lib/Notifications';
import { DateInput } from 'lib/DateInput';
import { Select } from 'lib/Select';

interface EditGenderModalContentProps {
	options: any[];
	onClose(): void;
}

const EditGenderModalContent = ({ options, onClose }: EditGenderModalContentProps) => {
	const [gender, setGender] = useState(userContainer.state.v2.gender);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!gender) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			gender: gender,
		});
		await userContainer.set(response.data);
		setIsLoading(false);
		onClose();
	};

	return (
		<>
			<TopBar title="Edit gender" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>Gender</FieldLabel>
					<Select
						placeholder={'Gender'}
						options={options}
						selectedOption={options.find(option => option.value === gender)}
						onChange={option => setGender(option.value)}
					/>
					<ActionBar>
						<Button isLoading={isLoading}>Save</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditGenderModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form`
	margin-bottom: 160px;
`;

const ActionBar = styled.div`
	margin-top: 24px;
`;
