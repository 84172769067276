import React, { useState } from 'react';
import userContainer from 'containers/user';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { notifyError } from 'lib/Notifications';
import { Select } from 'lib/Select';
import { CountryCodeSelect } from 'lib/CountryCodeSelect';

interface EditNationalityModalContentProps {
	onClose(): void;
}

const EditNationalityModalContent = ({ onClose }: EditNationalityModalContentProps) => {
	const [nationality, setNationality] = useState(userContainer.state.v2.nationality);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!nationality) {
			notifyError('All fields required');
			return;
		}
		setIsLoading(true);
		const response = await api.userV2.updateProfile({
			nationality: nationality,
		});
		await userContainer.set(response.data);
		setIsLoading(false);
		onClose();
	};

	return (
		<>
			<TopBar title="Edit nationality" onClose={onClose} />
			<Content>
				<Form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<FieldLabel>Nationality</FieldLabel>
					<CountryCodeSelect
						value={nationality}
						showDialCode={false}
						onChange={({ countryCode }) => {
							setNationality(countryCode);
						}}
					/>
					<ActionBar>
						<Button isLoading={isLoading}>Save</Button>
					</ActionBar>
				</Form>
			</Content>
		</>
	);
};

export default EditNationalityModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Form = styled.form`
	margin-bottom: 240px;
`;

const ActionBar = styled.div`
	margin-top: 24px;
`;
