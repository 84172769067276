import React, { useState } from 'react';
import userContainer from 'containers/user';
import authContainer from 'containers/auth';
import styled from 'styled-components';
import api from 'api';
import Button from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import theme from 'config/theme';
import useRouter from 'hooks/useRouter';
import routes from 'config/routes';
import { EventName, analyticsService } from 'services/analyticsService';

const { colors } = theme;

interface DeleteUserConfirmationModalContentProps {
	onClose(): void;
}

const DeleteUserConfirmationModalContent = ({ onClose }: DeleteUserConfirmationModalContentProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [goTo] = useRouter();

	const handleDelete = async () => {
		setIsLoading(true);
		await api.userV2.deleteUser();
		setIsLoading(false);
		await api.auth.logout();
		await userContainer.clear();
		await authContainer.clear();
		analyticsService.trackEvent(EventName.SetUser, { digs_user_id: '' });
		goTo(routes.home)
	};

	return (
		<>
			<TopBar title="Delete your account" onClose={onClose} />
			<Content>
				<Text>
					All your personal data and listings' details will be deleted. DigsConnect will be unable
					to retrieve any of your personal data in future.
				</Text>
				<ActionBar>
					<Button onClick={handleDelete} isRed isLoading={isLoading}>Delete account</Button>
				</ActionBar>
			</Content>
		</>
	);
};

export default DeleteUserConfirmationModalContent;

const Content = styled.div`
	padding: 16px 48px;
	padding-bottom: 32px;
	overflow-y: auto;
`;

const Text = styled.div`
	font-size: 16px;
	color: ${colors.grey60};
`;

const ActionBar = styled.div`
	margin-top: 24px;
`;
