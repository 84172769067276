import withAuth from 'components/common/with-auth';
import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import Header from 'components/common/header';
import Title from 'lib/Title';
import theme from 'config/theme';
import Main from 'components/common/main';
import Button from 'lib/Button';
import userContainer from 'containers/user';
import { Subscribe } from 'unstated';
import { ReactComponent as CameraIcon } from 'assets/images/add-digs/camera.svg';
import api from 'api';
import ThreeDotsLoader from 'lib/ThreeDotsLoader';
import modalContainer from 'containers/modal';
import ModalEnum from 'models/modalEnum';
import EditNameModalContent from './EditNameModalContent';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import EditEmailModalContent from './EditEmailModalContent';
import EditBirthdayModalContent from './EditBirthdayModalContent';
import EditIdentityNumberModalContent from './EditIdentityNumberModalContent';
import EditGenderModalContent from './EditGenderModalContent';
import DeleteUserConfirmationModalContent from './DeleteUserConfirmationModalContent';
import EditNationalityModalContent from './EditNationalityModalContent';
import countryCodes from 'constants/countryCodes';
import EditCellphoneModalContent from './EditCellphoneModalContent';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/icons/chevron-right.svg';

const { colors } = theme;

const ManageProfile = () => {
	const screenWidth = useScreenWidth();
	const [genderOptions, setGenderOptions] = useState([]);
	const history = useHistory();

	useEffect(() => {
		api.userV2
			.getGenderOptions()
			.then(res => res.data)
			.then(options => setGenderOptions(options))
			.catch(error => console.log(error));
	}, []);

	const handleEditName = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditNameModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};
	const handleEditEmail = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditEmailModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};
	const handleEditBirthdate = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditBirthdayModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};
	const handleEditIdentificationNumber = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditIdentityNumberModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};
	const handleEditNationality = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditNationalityModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};
	const [isUploading, setIsUploading] = useState(false);
	const fileInput = useRef(null);

	const handleImageUpdate = () => {
		(fileInput.current as any)?.click();
	};

	const handleEditGender = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditGenderModalContent options={genderOptions} onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};

	const handleEditCellphone = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<EditCellphoneModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};

	const handleFileInput = async (event: any) => {
		if (!event.target.files || !event.target.files.length) {
			return;
		}

		const file = event.target.files[0];
		const data = new FormData();
		data.append('file', file);
		setIsUploading(true);
		await api.userV2.uploadProfilePhoto(data);
		const userProfileV2Response = await api.userV2.getProfile();
		await userContainer.set(userProfileV2Response.data);
		setIsUploading(false);
	};

	const handleDelete = () => {
		modalContainer.open(
			ModalEnum.Generic,
			<DeleteUserConfirmationModalContent onClose={modalContainer.close} />,
			{ width: 500 },
		);
	};

	const profilePictureSection = (user: any) => (
		<ProfilePictureContainer onClick={handleImageUpdate}>
			<IconOverlay visible={isUploading}>
				{isUploading ? <ThreeDotsLoader /> : <CameraIcon style={{ width: 40, height: 40 }} />}
			</IconOverlay>
			<ProfilePictureDisplay src={user.state.v2.profile_picture?.url} />
		</ProfilePictureContainer>
	);

	return (
		<>
			<Header />
			<Main>
				<ScreenContainer>
					<ScreenContent>
						<BackAction onClick={() => history.goBack()}>
							<BackIcon style={{ transform: 'rotate(180deg)', marginRight: 12 }} /> Back to account
						</BackAction>
						<Subscribe to={[userContainer]}>
							{user => (
								<>
									{user.state.v2.first_name && (
										<Title standardMargin>Hey {user.state.v2.first_name},</Title>
									)}
									<Text>
										Welcome to your profile! This is where you get to tell us more about yourself
									</Text>
									<Container>
										<DetailsContainer>
											{screenWidth <= BREAKPOINT_TABLET_PX && (
												<CenteredRow>{profilePictureSection(user)}</CenteredRow>
											)}
											<FieldContainer>
												<div>
													<FieldLabel>Name</FieldLabel>
													<FieldDisplay>{`${user.state.v2.first_name || ''} ${user.state.v2
														.last_name || ''}`}</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditName}
												>
													Edit
												</Button>
											</FieldContainer>
											<FieldContainer>
												<div>
													<FieldLabel>
														Email address{' '}
														{!user.state.v2.email?.verified && (
															<WarningText color={colors.orange}>(not verified)</WarningText>
														)}
													</FieldLabel>
													<FieldDisplay>{user.state.v2.email?.email}</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditEmail}
												>
													Edit
												</Button>
											</FieldContainer>
											<FieldContainer>
												<div>
													<FieldLabel>
														Cellphone number{' '}
														{!user.state.v2.cellphone?.verified && (
															<WarningText color={colors.orange}>(not verified)</WarningText>
														)}
													</FieldLabel>
													<FieldDisplay>{user.state.v2.cellphone?.number || '-'}</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditCellphone}
												>
													Edit
												</Button>
											</FieldContainer>
											<FieldContainer>
												<div>
													<FieldLabel>Nationality</FieldLabel>
													<FieldDisplay>
														{countryCodes.find(value => value.code === user.state.v2.nationality)
															?.country || '-'}
													</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditNationality}
												>
													Edit
												</Button>
											</FieldContainer>
											<FieldContainer>
												<div>
													<FieldLabel>Identification number</FieldLabel>
													<FieldDisplay>{user.state.v2.identification_number || '-'}</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditIdentificationNumber}
												>
													Edit
												</Button>
											</FieldContainer>
											<FieldContainer>
												<div>
													<FieldLabel>Birth date</FieldLabel>
													<FieldDisplay>{user.state.v2.birth_date || '-'}</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditBirthdate}
												>
													Edit
												</Button>
											</FieldContainer>
											<FieldContainer>
												<div>
													<FieldLabel>Gender</FieldLabel>
													<FieldDisplay>{user.state.v2.gender || '-'}</FieldDisplay>
												</div>
												<Button
													noMargin
													maxWidth={80}
													height={40}
													isOutline
													onClick={handleEditGender}
												>
													Edit
												</Button>
											</FieldContainer>
											<DeleteButton onClick={handleDelete}>Delete account</DeleteButton>
										</DetailsContainer>
										{screenWidth > BREAKPOINT_TABLET_PX && (
											<RightPanel>{profilePictureSection(user)}</RightPanel>
										)}
									</Container>
								</>
							)}
						</Subscribe>
					</ScreenContent>
				</ScreenContainer>
				<input
					type="file"
					hidden
					ref={fileInput}
					onChange={handleFileInput}
					accept="image/jpeg, image/png"
				/>
			</Main>
		</>
	);
};

const IconOverlay = styled.div<{ visible?: boolean }>`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(256, 256, 256, 0.5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: ${({ visible }) => (visible ? 1 : 0)};

	&:hover {
		opacity: 1;
	}
`;

const ProfilePictureDisplay = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
`;

const ProfilePictureContainer = styled.div`
	display: flex;
	position: relative;
	border-radius: 100px;
	width: 160px;
	height: 160px;
	overflow: hidden;
	border: 1px solid ${colors.grey10};
	margin-bottom: 24px;
	cursor: pointer;
`;

const CenteredRow = styled.div`
	display: flex;
	justify-content: center;
`;

const RightPanel = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

const ScreenContainer = styled.div`
	border-top: 2px solid ${colors.grey10};
	width: 100%;
	padding: 24px;
`;

const ScreenContent = styled.div`
	margin: auto;
	margin-top: 24px;
	max-width: 1000px;
`;

const Text = styled.p`
	font-size: 14px;
	color: ${colors.grey60};
	margin: 0;
`;

const FieldLabel = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const FieldDisplay = styled.div`
	font-size: 16px;
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const FieldContainer = styled.div`
	border-bottom: 1px solid ${colors.grey10};
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DetailsContainer = styled.div`
	margin-top: 24px;
	flex: 1.5;
`;

const DeleteButton = styled.div`
	font-size: 16px;
	color: ${colors.red};
	font-weight: 500;
	margin-bottom: 48px;
	cursor: pointer;
`;

const Container = styled.div`
	display: flex;
`;

const WarningText = styled.div`
	color: ${colors.orange};
	display: inline;
	margin-left: 8px;
`;

const BackAction = styled.div`
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 24px;
	cursor: pointer;
	color: ${colors.darkBlue};
	font-size: 14px;
`;

export default withAuth(ManageProfile);
